import React, { useState } from 'react';

import UsersTable from "./components/UsersTable";
// import { AnimationOnScroll } from 'react-animation-on-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Clear } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import TextInput from '../../common/TextInput';
// ..
AOS.init();


// import '../App.scss';

const Users = () => {


  const [searchTerm, setSearchTerm] = useState("");
  const [role, setRole] = useState("all");
  const handleRoleChange = (newRole) => {
    setRole(newRole);
  };

  const showSearchBar = () => (
    <div className="search-bar">
      <TextInput type="search" name="" id="search-field" className="search-field" placeholder="Type to search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      {searchTerm && <Clear className="clear-search-icon" onClick={() => {
        setSearchTerm("");
        setRole("all")
      }} />}
    </div>
  );

  //button

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="users-page">

        <header className="template-header" >
          {/* <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Users &nbsp; {loadingBlogs ? <CircularProgress size={22} /> : null}</h1> */}
          <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Users</h1>
          <div className='action-bar' >
            <div className='filters' >
              {searchTerm == "" && role == "all" ? null : <button className='btn-primary-border' onClick={() => { setSearchTerm(""); setRole('all'); }}> Clear </button>}

              <button className='btn-primary' onClick={handleClick} > Filters </button>
              <Menu id="demo-positioned-menu" aria-labelledby="demo-positioned-button" anchorEl={anchorEl} open={open} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} style={{ marginTop: '40px' }} >
                <MenuItem onClick={() => { setRole("invited"); setAnchorEl(null); }}>Invited</MenuItem>
                <MenuItem onClick={() => { setRole("member"); setAnchorEl(null); }}>Member</MenuItem>
                <MenuItem onClick={() => { setRole("super"); setAnchorEl(null); }}>Super</MenuItem>
                <MenuItem onClick={() => { setRole("all"); setAnchorEl(null); }}>All</MenuItem>
              </Menu>
            </div>
          </div>
        </header>

        <section className='template-section' >
          <div className='users'>
          <UsersTable searchTerm={searchTerm} role={role} onRoleChange={handleRoleChange} />
          </div>
        </section>

      </div>
    </>
  );

}
export default Users;