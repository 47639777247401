  import { createContext, useState } from 'react';

/*const DrawerContext = createContext({
  details: {},
  drawOpen: false,
  setCurrentDrawer: (currentDrawer) => {},
  setDetails: (details) => {},
  isDrawerOpen: (isOpen) => {},
  openDrawer: (drawerState) => {}
});*/

const DrawerContext = createContext({
  open: false,
  drawer: 'none',
  details: {},
  setDetails: (setDetails) => {},
  openDrawer: (openDrawer) => {},
  closeDrawer: (closeDrawer) => {}
});

export function DrawerContextProvider(props) {

  const [open, setOpen] = useState(false);
  const [drawer, setDrawer] = useState('none');
  const [details, setDetails] = useState({});

  // const [details, setDetails] = useState([]);
  // const [drawOpen, setDrawOpen] = useState(false);
  // const [currentDrawer, setCurrentDrawer] = useState([]);
  // const [isDrawerOpen, setIsDrawerOpen] = useState([]);
  // const [drawerOpen, setDrawerOpen] = useState(false);

  // function isDrawerOpenHandler() {
  //   if(drawerOpen) {
  //     return true;
  //   }
  //   return false;
  // }

  /*function openDrawerHandler(drawerState) {
    console.log(drawerState);
    setDrawerOpen((preDrawerState) => {
      if(!preDrawerState != drawerState) {
        return true;
      }
      return false;
    });
  }*/
  /*function openDrawerHandler(drawerState) {
    console.log(drawerState);
    setDrawerOpen((preDrawerState) => {
      if(!drawerOpen) {
        return setDrawerOpen(true);
      }
      
    });
  }

  function openDrawerHandler(drawerState) {
    console.log(drawerState);
    setDrawerOpen((preDrawerState) => {
      if(!drawerOpen) {
        return setDrawerOpen(true);
      }
      
    });
  }

  function setCurrentDrawerHandler(drawer) {
    console.log(drawer);
    console.log("Are we here");
    setDrawOpen(true);
    console.log(drawOpen);
  }

  function setDetailsHandler(details) {
    console.log(details)
  }*/

  function openDrawerHandler() {
    setOpen(true);
  }

  function closeDrawerHandler() {
    setOpen(false);
  }

  function setDetailsHandler(drawer, data) {
    setDrawer(drawer);
    setDetails(data);
  }

  const context = {
    open: open,
    drawer: drawer,
    details: details,
    setDetails: setDetailsHandler,
    openDrawer: openDrawerHandler,
    closeDrawer: closeDrawerHandler
  };

  return <DrawerContext.Provider value={context}>
    {props.children}
  </DrawerContext.Provider>
}

export default DrawerContext;