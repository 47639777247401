import { useState, useContext, useEffect } from 'react';
import { Modal, Box } from '@mui/material';

import ModalContext from '../context/ModalContext.js';

import DeleteTopicModal from "../utils/Modals/DeleteTopicModal.jsx";
import DeleteNetworkModal from '../utils/Modals/Networks/DeleteNetworkModal.jsx';

const DetailsModal = () => {

  const modalCtx = useContext(ModalContext);

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const currentModal = () => {
    switch (modalCtx.modal) {
      //Network
      case "delete-network": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteNetworkModal details={modalCtx.details} /></Box></Modal>;


      case "delete-topic": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTopicModal details={modalCtx.details} /></Box></Modal>;
    }
  }

  return (
    <div>{currentModal()}</div>
  );

}

export default DetailsModal;
