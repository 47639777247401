import React from 'react'

// images
import mapImage from '../../assets/img/Location_of_Continents.svg'


const UniverseNueOverView = () => {

 // test data
    const TestData = [
        { heading: 'Total No. of Accounts', count:50, percentage:'15% last week', type:"profit" },
        { heading: 'Monthly Active Users', count: 65, percentage: '10% last week', type: "loss" },
        { heading: 'No. of New Accounts Per Month', count: 24, percentage: '10% last week', type: "profit" },
        { heading: 'No. of paid creators', count: 8, percentage: '10% last week', type: "loss" },
        { heading: 'No of New Paid Users Per Month', count: 5, percentage: '15% last week', type: "profit" },
    ];

    const TestDatasecond = [
        { heading: 'Monthly Recurring Revenue (MRR)', count:100, percentage:'15% last week', type:"profit" },
        { heading: 'New Monthly Recurring Revenue (NMRR)', count: 120, percentage: '10% last week', type: "loss" },
        { heading: 'Church Monthly Recurring Revenue (CMRR)', count: 100, percentage: '10% last week', type: "profit" },
        { heading: 'Average Revenue Per Account', count: 50, percentage: '10% last week', type: "loss" },
    ];
    const mapdata = [
        { heading: 'Asia', color:"#2275B9", count:200 },
        { heading: 'North America', color:"#BB4468", count:130 },
        { heading: 'Europe', color:"#CD4A4A", count:100 },
        { heading: 'South America', color:"#F2642C", count:85 },
        { heading: 'Australia', color:"#F58826", count:65 },
        { heading: 'Africa', color:"#844094", count:40 },
        { heading: 'Antarctica', color:"#1BA0A2", count:25 },
        
    ];


  return (
      <div className="universe-page">
          <header className='universe-header'>
              <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Universe - NUE - Overview</h1>

          </header>

          <section className='universe-section overview-universe' >
              <div className='main-head'>
                  {TestData?.map(( item, index) => (
                      <div key={index} className='universe-overview'>
                          <h3>{item?.heading}</h3>
                          <h1>{ item.count}</h1>
                           <div className='overview-headcontent'>
                             <img src='' alt='arrow' /> 
                              <p style={{ color: item?.type === "loss" ? "#E71D1D" : "#1AB53F", fontSize:"14px" }}>{item?.percentage}</p>
                            </div>
                        </div>

                     ))}
              </div>

              <div className='second-head'>
                  {TestDatasecond?.map(( item, index) => (
                      <div key={index} className='universe-overview-second'>
                          <h3>{item?.heading}</h3>
                          <h1>${item.count}</h1>
                           <div className='overview-headcontent-second'>
                             <img src='' alt='arrow' /> 
                              <p style={{ color: item?.type === "loss" ? "#E71D1D" : "#1AB53F", fontSize:"14px",  }}>{item?.percentage}</p>
                            </div>
                        </div>

                     ))}
              </div>
              
                 <div className='map-section'>
                  <h2>Geographical Region of Each Account</h2>
                  <img src={mapImage} alt='map' />
                  <div className='map-about'>
                      {mapdata?.map((item, index) => ( 
                          <div key={index} className='map-about-container'>
                              <span style={{ background: item?.color }} className='map-color'></span>

                        <div className='map-about-content'>
                                  <p>{item?.heading}</p>
                                  <h3>{item?.count}</h3>

                              </div>
                      </div>
                     
                         ))}
                      
                  </div>
              </div>

              <div className='statistic-section'>
                  <div className='section-statistic-one'></div>
                  <div className='section-statistic-two'></div>
                  

              </div>
              

        </section>
        </div>
  )
}

export default UniverseNueOverView;
