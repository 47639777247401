import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@mui/material';

import AuthContext from '../../context/AuthContext.js';
import ModalContext from '../../context/ModalContext.js';

const DeleteTopicModal = (props) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  
  console.log(modalCtx);
  const handleDeleteTopic = () => {
    // axios.delete(process.env.REACT_APP_API_URI + '/v1/accounts/' + details.id, {
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    // })
    // .then(response => response.data)
    //   .then(result => {
    //     console.log(result);
    //     if (result.status === "success") {
    //       modalCtx.closeModal();
    //       modalCtx.setDetails(null);
    //       modalCtx.setPageUpdate({page: "goals"});
    //     } else {
    //       console.log("failed to delete");
    //     }
    //   }).catch(err => console.error(err));
  }

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }


  return (
    <div className="modal">
      <p>Are you sure you want to delete the Topic?</p>

      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => { handleDeleteTopic() }}>Yes</Button>
      </div>
    </div>
  )
}

export default DeleteTopicModal;
