import React, { useState, useEffect, useContext } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

import { Button } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import DrawerContext from '../../../context/DrawerContext.js';

import editIcon from '../../../assets/img/delete.png'
import genralIcon from '../../../assets/img/general.png'
import memeberIcon from '../../../assets/img/members.png'
import networkIcon from '../../../assets/img/Networks.png'

import { IconButton, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';


const noMenuRoutes = ["/login"];
const dummyData = [
  { id: 1, type: 'Bug', description: 'App is crashing on launch', status: 'Open' },
  { id: 2, type: 'Feature Request', description: 'Add support for dark mode', status: 'In Progress' },
  { id: 3, type: 'Bug', description: 'Incorrect pricing on the checkout page', status: 'Resolved' },
  { id: 4, type: 'Feature Request', description: 'Add support for multiple file uploads', status: 'Open' },
];

function TicketsTable() {
  const drawerCtx = useContext(DrawerContext);

  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [state, setState] = React.useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  //pop menu 


  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState('');
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleOpenPopup = (event, row) => {
    event.stopPropagation();
    setSelectedTicket(row);
    setAnchorEl(event.currentTarget);
    setSelectedStatus(row.status);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  // popmenu ends

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(dummyData.map((row) => row.id));
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  const handleSelectRow = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, id];
    } else {
      selectedRows.splice(selectedIndex, 1);
      newSelectedRows = selectedRows;
    }

    setSelectedRows(newSelectedRows);
    setSelectAll(newSelectedRows.length === dummyData.length);
  };

  const getTicketType = (type) => {

    if (type == "network_report") {
      return (<div className='type network'>
        <img src={networkIcon}></img>
        Network Report

      </div>);
    } else if (type == "member_report") {
      return (<div className='type member'>
        <img src={memeberIcon}></img>
        Member Report

      </div>);
    } else if (type == "general_report") {
      return (<div className='type general'>
        <img src={genralIcon}></img>
        General Report

      </div>);
    }

  }

  const getPopupDiv = (row) => {
    if (selectedStatus == "open") {
      return (
        <>
          <Button variant='outlined' style={{ color: '#CD4A4A', borderColor: '#CD4A4A' }}>
            Disable Network
          </Button>
          <Button variant='contained' style={{ color: 'white', backgroundColor: '#CD4A4A' }}>
            Dismiss
          </Button></>
      );
    } else if (row.status == "closed") {
      return (
        <>
          <Button variant='contained' style={{ color: 'white', backgroundColor: '#CD4A4A' }}>
            Reply to Issue
          </Button></>
      )
    } else if (row.status == "pending") {
      return (
        <>
          <Button variant='outlined' style={{ color: '#CD4A4A', borderColor: '#CD4A4A' }}>
            Block
          </Button>
          <Button variant='contained' style={{ color: 'white', backgroundColor: '#CD4A4A' }}>
            Dismiss
          </Button></>
      )

    }

  }
  const getStatus = (status) => {

    if (status == "opened" || status == "pending") {
      return (<div className='status open'>

        {status}

      </div>);
    } else if (status == "closed") {
      return (<div className='status closed'>

        {status}

      </div>);
    }

  }

  // const dataColumns = [
  //   {
  //     field: 'ticket_type', headerName: "Ticket Type", width: 320, sortable: false,
  //     renderCell: (params) => params.row.ticket_type
  //   },
  //   {
  //     field: 'desc', headerName: 'Description', width: 700, cellClassName: 'overflow-cell',
  //     renderCell: (params) => params.row.description
  //   },
  //   {
  //     field: 'status', headerName: 'Status', width: 150,
  //     renderCell: (params) => params.row.status
  //   },
  // ];

  useEffect(() => {

    getTickets()

  }, []);

  const getTickets = () => {

    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v2/tickets', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setIsLoading(false);
        setTickets(data.data);
      });

  }

  if (isLoading) {
    return <section><CircularProgress /></section>
  }

  // console.log(tickets);
  // console.log("col", dataColumns);

  return (
    <>
      <TableContainer className='tickets-table'>
        <Table className='table'>
          <TableHead className='table-head' style={{ width: '100%', position: "sticky", top: "0", backgroundColor: '#F0F1F3', color: '#9B9B9B', zIndex: "100" }} >
            <TableRow sx={{ color: '#9B9B9B' }} className='head-row'>
              <TableCell padding="checkbox" className='head-cell'>
                <Checkbox indeterminate={selectedRows.length > 0 && selectedRows.length < dummyData.length} checked={selectAll} onChange={handleSelectAll} />
              </TableCell>
              <TableCell className='head-cell'>Ticket Type</TableCell>
              <TableCell className='head-cell'>Description</TableCell>
              <TableCell className='head-cell'>Status</TableCell>
              <TableCell className='head-cell'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='table-body'>
            {tickets.map((row) => (
              <TableRow className='body-row' key={row.id} selected={selectedRows.indexOf(row.id) !== -1} hover >
                <TableCell padding="checkbox" className='body-cell'>
                  <Checkbox
                    checked={selectedRows.indexOf(row.id) !== -1}
                    onClick={(event) => handleSelectRow(event, row.id)}
                  />
                </TableCell>
                <TableCell className='body-cell'>
                  {getTicketType(row.ticket_type)}
                </TableCell>
                <TableCell className='body-cell' >
                  <span className='heading'>Anna Mark</span>
                  <p>{row.description}</p></TableCell>
                <TableCell className='body-cell'>{getStatus(row.status)}</TableCell>
                <TableCell className='body-cell action'>

                  <IconButton onClick={(event) => handleOpenPopup(event, row)}>
                    <MoreVertIcon />
                  </IconButton>
                  <img src={editIcon} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClosePopup} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} transformOrigin={{ vertical: 'top', horizontal: 'center', }} style={{ marginTop: '5px' }} >
        <div className='pop-div'>
          {getPopupDiv(selectedTicket)}
        </div>
      </Popover>
    </>
  )
}

export default TicketsTable