import React, { useState } from 'react'
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';



import mobileImage from '../../assets/img/phoneuniverse.png';
import desktopImage from '../../assets/img/desktopuniverse.png';
import TextInput from '../../common/TextInput';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
        <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};






const UniverseNue = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  const handleChangeColor = () => {
    
  }
  return (
      <div className="universe-page">
          <header className='universe-header'>
              <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Universe - NUE</h1>
              
          </header>

          <section className='universe-section main' >
              <div className='section-one'>  
              <Tabs className="heading" value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="Mobile" />
                  <Tab label="Desktop" />
              </Tabs>
              <TabPanel className="contentmob" value={value} index={0}>
                      <img style={{ width: "200px"}} src={mobileImage} alt="mobile" />

              </TabPanel>
                  <TabPanel className="contentdesktop" value={value} index={1}>
                      <img style={{ width: "500px" }} src={desktopImage}  alt="desktop"/>
                  </TabPanel>
              </div>
        <div className='section-two'>
          <div className='content'> 
            <h3>Choose Universe Colors</h3>
          
          <div className='color-box'>
              <TextInput label=" Top Navigation Bar" type='color' value={"#844094"} onChange={handleChangeColor} />
          </div>

          <div className='color-box'>  
              <TextInput label="Bottom Navigation Bar" type='color' value={"#844094"} onChange={handleChangeColor } />
            </div>
            
            <div className='color-box'>
              <TextInput label="Backgorund" type='color' value={"#FFFFFF"} onChange={handleChangeColor} />
            </div>

            <div className='color-box'>
              <TextInput label="Font" type='color' value={"#000"} onChange={handleChangeColor} />
            </div>

            <button className='btn-save'>
            Save Colors
            </button>
          </div>


              </div>
            
          </section>
          </div>
  )
}

export default UniverseNue
