import react, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { Paper, Grid, TextField, CardContent, Button } from '@mui/material';
import { Checkbox, Snackbar } from '@mui/material';


import AuthContext from '../../context/AuthContext.js';

import Logo from "../../assets/img/login.png"



const Login = (props) => {

	let navigate = useNavigate();
	const authCtx = useContext(AuthContext);
			
	const [email, setEmail]= useState('');
	const [password, setPassword] = useState('');
	const [redirect, setRedirect] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("Sorry, email or password incorrect");

	function submit(e) {
		e.preventDefault()

		authCtx.login(email, password);
		// fetch(process.env.REACT_APP_API_URI + '/v1/adminlogin', {
		// 	method: 'POST',
		// 	mode: 'cors',
		// 	headers: { 'Content-Type': 'application/json' },
		// 	body: JSON.stringify({
		// 		email,
		// 		password
		// 	})
		// }).then(response => {
		// 	return response.json();
		// }).then(data => {
		// 	console.log(data);
		// 	if(data.status == "success") {
		// 		const tokenExpiration 	= new Date(new Date().getTime() + 86400000);
		// 		authCtx.login(data.data.token, data.data.userId, data.data.userName, data.data.userEmail, data.data.userScope, tokenExpiration);
		// 		navigate("/overview", { replace: true });
		// 	} else {
		// 		setSnackbarMessage(data.message);
		// 		setOpenSnackbar(true);
		// 	}
		// });

	}

	function changeEmail(event) {
		setEmail(event.target.value);
	}

	function changePassword(event) {
		setPassword(event.target.value);
	}

	const paperStyle = { padding: 20, width: 580, margin: "0 auto" }
		return (
			<div className="login-register">

				<div className="login">

					<Paper elevation={2} style={paperStyle}>
						<CardContent>
							<div className='head'>
							<img src={Logo} alt="logo" className='logo-image'></img>
							<h1 className="form-heading">Sign In</h1>
							</div>
							

							<div className="form-wrapper">
								<form>
									<Grid container spacing={4}>

										<Grid xs={12} item>
											<TextField  onChange={changeEmail} value={email} placeholder="Email" variant="outlined" fullWidth required />
										</Grid>

										<Grid xs={12} item>
											<TextField onChange={changePassword} value={password} type="password" placeholder="Password" variant="outlined" fullWidth required />
										</Grid>

									</Grid>

									
									<button className="btn-primary w-100" onClick={submit} style={{ color: 'white', backgroundColor: '#CD4A4A', marginTop: '1rem' }}>Sign In</button>
									{/* <div className="no-account-wrapper">
											Don't have an account?
											<Link to="/register"> join free today</Link>
									</div> */}

									<div className="remember-forgot-wrapper">
										{/* <div className="circle-checkbox">
											<Checkbox name="checked8" color="primary" />Remember Me
										</div> */}
									
										{/* <a href=''>Forgot Password ?</a> */}
									</div>
								</form>
							</div>
							<Snackbar open={openSnackbar} autoHideDuration={1000} message={snackbarMessage}></Snackbar>
							

						</CardContent>
					</Paper>

				</div>
			</div>
		);
	}

export default Login;









