import React, { useEffect, } from 'react'

import TicketsTable from './components/TicketsTable';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const Tickets = () => {

  return (
    <>
      <div className="tickets-page">

        <header className="template-header" >
          {/* <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Tickets &nbsp; {loadingBlogs ? <CircularProgress size={22} /> : null}</h1> */}
          <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Tickets</h1>
          <div className='action-bar' >

          </div>
        </header>

        <section className='template-section' >
          <div className='tickets'>
            <TicketsTable />
          </div>
        </section>

      </div>
    </>

  )
}

export default Tickets