import React, { useState, useContext } from "react";

import DrawerContext from "../../context/DrawerContext";

import CircularProgress from '@mui/material/CircularProgress';
import TextInput from "../../common/TextInput";

function EditTopicDrawer(props) {
  
  const [topic, setTopic] = useState(props.details.topic);
  const [isLoading, setIsLoading] = useState(false);

  const drawerCtx = useContext(DrawerContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // fetch(process.env.REACT_APP_API_URI + "/v1/accounts", {
    //   method: "PUT",
    //   body: JSON.stringify({
    //     clientID: props.details.id,
    //     title: client,
    //   }),
    //   headers: { "Content-Type": "application/json; charset=UTF-8" },
    // })
    //   .then((response) => response.json())
    //   .then((data) => console.log(data))
    //   .then(setIsLoading(false))
    //   .catch(err => {
    //     if(err) {
    //       console.log(err);
    //       setIsLoading(false);
    //     }
    //   });
  };

  return (

    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Edit Topic</h1>
        </header>

        <section style={{ padding: '1rem' }}>
          <div className="double-column-form">
            <TextInput label="Topic Name" value={topic} onChange={(e) => setTopic(e.target.value)} placeholder="Topic Name" />
          </div>

          <div className="double-column-form aligned-right">
            {
              isLoading ? <CircularProgress /> :
                <button className="btn-primary" onClick={handleSubmit} type="submit" disabled={topic === "" || topic === null || topic === props.details.topic} >
                  Save Changes
                </button>
            }
          </div>
        </section>
      </div>
    </div>
  );
}
export default EditTopicDrawer;
