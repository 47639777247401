import React from 'react';

const Overview = () => {

	return (
		<div className="container">
			<header>
				<div data-aos="fade-up">
					<h1>Overview</h1>
				</div>
			</header>
		</div>
	);
}
export default Overview;