import React, { useState, useEffect, useContext } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'; 
import CircularProgress from '@mui/material/CircularProgress';


import DrawerContext from '../../../context/DrawerContext.js';
import ModalContext from '../../../context/ModalContext.js';


function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

function showRegDate(date) {
	return date.substring(0, 10);
}

function showStatus(status) {
	if (status == 1) {
		return "active";
	}
	return "inactive";
}

const rows = [];

function TopicsTable(props) {
	
	const noMenuRoutes = ["/login"];
	
	const drawerCtx = useContext(DrawerContext);
	const modalCtx	= useContext(ModalContext);

	const [state, setState] 								= React.useState(false);

	const [isLoading, setIsLoading] 				= useState(true);
	const [topics, setTopics] 		= useState([]);
	
	// console.log(topics);
	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

  function toggleModalHandler(modal, data) {
		modalCtx.openModal();
    modalCtx.setDetails(modal, data);
		// console.log(modal, data);
  };

	useEffect(() => {
    
		setIsLoading(true);
		fetch(process.env.REACT_APP_API_URI + '/v2/topics', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token }
		})
		.then(response => {
      return response.json();
		}).then(data => {
      console.log(data);
      setIsLoading(false);
      setTopics(data.data);
		});
    // console.log(topics);
	}, []);

	if (isLoading) {
		return <section><CircularProgress /></section>
	}

	return (
		<>
			<TableContainer className='topics-table'>
				<Table aria-label="simple table" data-aos="fade-up">
					<TableHead style={{ width: '100%', position: "sticky", top: "0", backgroundColor: '#F0F1F3', color: '#9B9B9B', zIndex: "100" }} >
						<TableRow>
							<TableCell>Topics</TableCell>
							<TableCell align='right'>Amount of Networks</TableCell>
							<TableCell align="right">&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{topics?.map((data) => (
							<TableRow key={ data.id }>
								<TableCell>{ data.topic }</TableCell>								
								<TableCell align='right'>{ data.amount_of_networks }</TableCell>
								<TableCell align="right" width="15%">
									<EditIcon className="table-tool" onClick={() => {toggleDrawerHandler('edit-topic', data)}} />
									<DeleteIcon className="table-tool" onClick={() => {toggleModalHandler('delete-topic', data)}} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

export default TopicsTable;