import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

//mui
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import { Link } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import { Menu, MenuItem, TablePagination } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

//assets
import editIcon from '../../../assets/img/delete.png'

//context
import DrawerContext from '../../../context/DrawerContext.js';
import AuthContext from "../../../context/AuthContext.js";
import ModalContext from '../../../context/ModalContext';

//firebase
import { ref, getDownloadURL } from "firebase/storage";
import storage from "../../../common/FirebaseConfig";

const noMenuRoutes = ["/login"];

function AvatarImage({ coverRef, title }) {

  const authCtx = useContext(AuthContext);
  const { pathname } = useLocation();
  const [avatarImage, setAvatarImage] = useState("");

  useEffect(() => {
    if (coverRef != "") {
      getImg(coverRef);
    }
  }, [coverRef]);

  function getRandomColor(name) {

    let bgColorForSpan = 'rgb(0, 0, 0, 0.3)';
    let firstAlphabet = "A";
    // console.log(name);
    if (name) {
      // get first alphabet in upper case
      firstAlphabet = name.charAt(0).toLowerCase();

      // get the ASCII code of the character
      const asciiCode = firstAlphabet.charCodeAt(0);

      // number that contains 3 times ASCII value of character -- unique for every alphabet
      const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

      var num = Math.round(0xffffff * parseInt(colorNum));
      var r = num >> 16 & 255;
      var g = num >> 8 & 255;
      var b = num & 255;
      bgColorForSpan = 'rgb(' + r + ', ' + g + ', ' + b + ', 0.3)'
    }
    return <div className='avatar-text' style={{ background: bgColorForSpan }}>{firstAlphabet.toUpperCase()}</div>

  }

  const getImg = (coverRef) => {
    getDownloadURL(ref(storage, coverRef))
      .then((url) => {
        setAvatarImage(url);
      })
      .catch((error) => {
        console.log(error)
      });
  }


  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  return (
    <>
      {avatarImage == "" ? getRandomColor(title) : <img className='avatar-text' src={avatarImage} alt="avatar" />}
    </>
  );
}


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function showRegDate(date) {
  return date.substring(0, 10);
}

function UsersTable({ searchTerm, role, onRoleChange }) {

  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);



  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [topics, setTopics] = useState([])
  const [state, setState] = React.useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const rowsPerPageOptions = [10, 20, 30, 40, 50, 100];

  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[2]);
  const [startNo, setStartNo] = useState(0);
  const [endNo, setEndNo] = useState(rowsPerPageOptions[2]);
  const [filterStatus, setFilterStatus] = useState("all");

  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor element



  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };
  function toggleModalHandler(modal, data) {
    setAnchorEl(null);
    modalCtx.setDetails(modal, data);
    modalCtx.openModal();

    // console.log(modal, data);
  };

  useEffect(() => {

    fetch(process.env.REACT_APP_API_URI + '/v2/users', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setIsLoading(false);
        setUsers(data.data);
      });

  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filterUsers = (term, status) => {
        if (status == 'all') {
          return users?.filter((data) => {
            const values = Object.values(data);
            const includesTerm = values.join('').toLowerCase().includes(term?.toLowerCase());
            return includesTerm;
          });
        } else {
          return users?.filter((data) => {
            const values = Object.values(data);
            const includesTerm = values.join('').toLowerCase().includes(term?.toLowerCase());

            const includesStatus = values.includes(status);
            return includesTerm && includesStatus;
          });
        }

      }
      setFilteredUsers(filterUsers(searchTerm, role));
      setPage(0);
      setStartNo(0);
      setEndNo(rowsPerPage);
    }
    else if (role != "all") {

      const filterUsers = (status) => {
        return users?.filter((data) => {
          const values = Object.values(data);
          const includesStatus = values.includes(status);
          return includesStatus;
        });


      }
      setFilteredUsers(filterUsers(role));
      setPage(0);
      setStartNo(0);
      setEndNo(rowsPerPage);
    } else {
      setFilteredUsers(users);
    }
  }, [users, users?.length, searchTerm, role]);


  // const dataColumns = [
  //   {
  //     field: 'cover', headerName: "", width: 80, sortable: false,
  //     renderCell: (params) => <AvatarImage title={params.row.name} />
  //   },
  //   {
  //     field: 'title', headerName: 'Full Name', width: 210,
  //     renderCell: (params) => <Link className="user-info-link" onClick={() => toggleDrawerHandler('user-details', params.row)}>{params.row.name}</Link>
  //   },
  //   { field: 'email', headerName: 'Email', width: 200, },
  //   { field: 'date', headerName: 'Registration Date', width: 140, },
  //   {
  //     field: 'status', headerName: 'Status', width: 80,
  //     // valueGetter: (params) => showStatus(params.row.status) 
  //   },
  //   {
  //     field: 'actions', headerName: 'Actions', width: 120,
  //     renderCell: (params) => <Button className="see-more-btn" onClick={() => toggleDrawerHandler('user-details', params.row)}>See More</Button>
  //   },
  // ];

  const handleSelectAllRows = (event) => {
    if (event.target.checked) {
      const newSelectedRows = filteredUsers.map((row) => row.id);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelectedRows);
  };

  const handleChangePage = (event, newPage) => {
    setStartNo(newPage * rowsPerPage);
    if (newPage * rowsPerPage + rowsPerPage < filteredUsers.length || newPage * rowsPerPage + rowsPerPage == filteredUsers.length) {
      setEndNo(newPage * rowsPerPage + rowsPerPage);
    } else {
      setEndNo(filteredUsers.length);
    }

    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element to the clicked element
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const isChecked = (id) => {
    let currentNetwork = users.find(function (obj) { return obj.id == id });
    if (currentNetwork && currentNetwork.featured == "on") {
      return true;
    }
    return false;
  }

  const addFeatured = (event, id) => {
    let newUsers = [];
    users.forEach((net) => {
      if (net.id == id) {
        if (event == "checked") {
          net.featured = "on";
          updateFeatured(net.id, "on");
        } else {
          net.featured = "off";
          updateFeatured(net.id, "off");
        }
      }
      newUsers.push(net);
    });
    setUsers(newUsers);
  };

  const updateFeatured = (id, featured) => {

    let payload = {
      id: id,
      featured: featured
    }

    fetch(process.env.REACT_APP_API_URI + '/v2/user/' + id + '/setFeatured', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
    });

  }

  const showBgColor = (status) => {
    // console.log(status);
    if (status == 'deleted') {
      return { backgroundColor: '#c0392b', color: '#fff' };
    } else if (status == 'super') {
      return { backgroundColor: '#3498db', color: '#fff' };
    } else if (status == 'admin') {
      return { backgroundColor: '#f39c12', color: '#fff' };
    } else if (status == 'invited') {
      return { backgroundColor: '#95a5a6', color: '#fff' };
    } else if (status == 'member') {
      return { backgroundColor: '#27ae60', color: '#fff' };
    }
    return { backgroundColor: '#95a5a6', color: '#fff' };
  }


  if (isLoading) {
    return <section><CircularProgress /></section>
  }

  return (
    <>
      <TableContainer className='users-table'>
        <Table className='table' aria-label="simple table" data-aos="fade-up">
          <TableHead style={{ width: '100%', position: "sticky", top: "0", backgroundColor: '#F0F1F3', color: '#9B9B9B', zIndex: "100" }}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < filteredUsers.length
                  }
                  checked={selectedRows.length === filteredUsers.length}
                  onChange={handleSelectAllRows}
                />
              </TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Registration Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredUsers.slice(startNo, endNo).map((row) => (
                <TableRow key={row.id} hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRows.includes(row.id)}
                      onChange={(event) => handleSelectRow(event, row.id)}
                    />
                  </TableCell>
                  <TableCell><div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}><AvatarImage coverRef={row.avatar || ""} title={row.name} /><span>{row.name}</span></div></TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>10 Mar 2023</TableCell>
                  <TableCell><Chip label={row.role} style={showBgColor(row.role)} onClick={() => {
                    // setFilterStatus(row.role);
                    onRoleChange(row.role);
                    console.log("clicking", row.role);
                  }} /></TableCell>
                  <TableCell>
                    {isChecked(row.id) ? <BookmarkIcon onClick={(e) => addFeatured("unchecked", row.id)} /> : <BookmarkBorderIcon onClick={(e) => addFeatured("checked", row.id)} />}
                    <MoreVertIcon className="table-tool" onClick={handleClick}
                    //  onClick={() => {toggleDrawerHandler('user-details', row)}}
                    />
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} style={{ marginTop: '48px' }}>
                      <MenuItem onClick={() => { toggleModalHandler('delete-network', row) }}  >Promote <img src={editIcon} onClick={() => toggleDrawerHandler('user-details', row)}></img> </MenuItem>
                      <MenuItem onClick={() => { toggleDrawerHandler('user-details', row) }} >Details</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <div className="table-pagination" style={{ width: '100%', position: "sticky", bottom: "0", right: "0", left: "0", backgroundColor: '#F0F1F3', zIndex: "100" }}>
          <TablePagination rowsPerPageOptions={rowsPerPageOptions} component="div" count={filteredUsers.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </div>
      </TableContainer>

    </>
  );
}

export default UsersTable;