
import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@mui/material'

import CreateNetworkDrawer from '../utils/Drawers/CreateNetworkDrawer';

import DrawerContext from '../context/DrawerContext.js';
import EditTopicDrawer from '../utils/Drawers/EditTopicDrawer';
import UsersDrawer from '../utils/Drawers/UsersDrawer';

const noMenuRoutes = ["/login", "/register"];

function DetailsDrawer(props) {

  const drawerCtx = useContext(DrawerContext);
  const { pathname } = useLocation();

  function closeDrawer() {
    drawerCtx.closeDrawer();
  }

  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  const currentDrawer = () => {
    switch (drawerCtx.drawer) {

      // Users
      case "user-details": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><UsersDrawer userDetails={drawerCtx.details} /></Drawer>;
      case "create-user": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>Create New User</h1></Drawer>;
      case "create-network": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><CreateNetworkDrawer /></Drawer>;
      case "edit-topic": return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><EditTopicDrawer details={drawerCtx.details} /></Drawer>;

      default: return <Drawer anchor='right' open={drawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>;
    }
  }

  return (
    <div>{currentDrawer()}</div>
  );
}
export default DetailsDrawer;
