import React, { useContext } from 'react';

import TopicsTable from "./components/TopicsTable.jsx";

import DrawerContext from '../../context/DrawerContext.js';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();

const Topics = () => {

	const drawerCtx = useContext(DrawerContext);

	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	return (
    <>
    <div className="topics-page">

      <header className="template-header" >
        {/* <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Topics &nbsp; {loadingBlogs ? <CircularProgress size={22} /> : null}</h1> */}
        <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Topics</h1>
        <div className='action-bar' >
          <button className='btn-primary' onClick={() => {toggleDrawerHandler('create-topic', null)}} >Create</button>
        </div>
      </header>

      <section className='template-section' >
        <div className='topics'>
        <TopicsTable />
        </div>
      </section>

    </div>
  </>
	);

}
export default Topics;