import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// context
import AuthContext from '../../context/AuthContext.js';

// assets
import overview from "../../assets/img/sidebar/overView.svg";
import networks from "../../assets/img/sidebar/Networks.svg";
import users from "../../assets/img/sidebar/users.svg";
import topics from "../../assets/img/sidebar/topics.svg";
import tickets from "../../assets/img/sidebar/tickets.svg";
import admin from "../../assets/img/sidebar/administrators.svg";
import logout from "../../assets/img/sidebar/logout.svg";
import Logo from '../../assets/img/login.png';
import universe from '../../assets/img/sidebar/universe.svg';
import logouniverse from '../../assets/img/sidebar/logoUniverse.png';
import duallogouniverse from '../../assets/img/sidebar/dualsidebarlogo.svg';
// assets MIU
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded, AddRounded } from '@mui/icons-material';
import { Button } from '@mui/material';


const Sidebar = ({ setSidebarOpen }) => {
	
	//mics
	const noSideBarRoutes = ["/login", "/register"];
	const { pathname } = useLocation();
	let navigate = useNavigate();

	//context
	const authCtx = useContext(AuthContext);
	const isLoggedIn = authCtx.isLoggedIn;
	
	//states
	const [activeItem, setActiveItem] = useState('overview')
	//boolean states
	const [sidebarClosed, setSidebarClosed] = useState(false)
	const [UniverseDualbar, setUniverseDualbar] = useState(false)
	
	//effects
	
	useEffect(() => {
		if(pathname.slice(1) === ""){
			setActiveItem("overview")
			setUniverseDualbar(false)

		} else {
			setActiveItem(pathname.slice(1))
			if (pathname.slice(1) == "universe/nue") {
				
				setUniverseDualbar(true)
			} else {
				setUniverseDualbar(false)

			}
		}
		
	
	}, [])
	

	
	//functions

	const handleItem = (path , item) => {
		navigate(path); 
		setActiveItem(item)
	  }

	const handleSideBar = () => {
		if(sidebarClosed){
			setSidebarClosed(false)
			setSidebarOpen(true)
		} else {
			setSidebarClosed(true)
			setSidebarOpen(false)
		}
	}

	const handleUniverse = () => {
       setUniverseDualbar(true)

	}


	if (noSideBarRoutes.some((item) => pathname.includes(item))) return null;
   

	const isUniverseRoute = pathname.includes("universe/nue");
	return (
		<>{
			UniverseDualbar == false && !isUniverseRoute ? 
		<div className = "menu" >
			<div className="company-logo"><img src={Logo} /></div>
			<nav>
				{!isLoggedIn && (<Link to="/login"><img src={logout} />Login</Link>)}
				{isLoggedIn && (<Link to="/overview"><img src={overview} />Overview</Link>)}
				{isLoggedIn && (<Link to="/networks"><img src={networks} />Networks</Link>)}
				{isLoggedIn && (<Link to="/users"><img src={users} />Users</Link>)}
				{isLoggedIn && (<Link to="/topics"><img src={topics} />Topics</Link>)}
				{isLoggedIn && (<Link to="/administrators"><img src={admin} />Administrators</Link>)}
				{isLoggedIn && (<Link to="/tickets"><img src={tickets} />Tickets</Link>)}
				{isLoggedIn && (<Link to="/universe" ><img src={universe} />Universe</Link>)}
				{isLoggedIn && (<a onClick={() => authCtx.logout()}><img src={logout} />Logout</a>)}
			</nav>
			
				</div> :
				<div className="menu-universe" >
					<div className='company-content'>
						<div className="company-logo-universe"><img src={logouniverse} /></div>
						<nav>
							{!isLoggedIn && (<Link to="/login"><img src={logout} /></Link>)}
							{isLoggedIn && (<Link to="/overview"><img src={overview} /></Link>)}
							{isLoggedIn && (<Link to="/networks"><img src={networks} /></Link>)}
							{isLoggedIn && (<Link to="/users"><img src={users} /></Link>)}
							{isLoggedIn && (<Link to="/topics"><img src={topics} /></Link>)}
							{isLoggedIn && (<Link to="/administrators"><img src={admin} /></Link>)}
							{isLoggedIn && (<Link to="/tickets"><img src={tickets} /></Link>)}
							{isLoggedIn && (<Link to="/universe"><img src={universe} /></Link>)}
							{isLoggedIn && (<a onClick={() => authCtx.logout()}><img src={logout} /></a>)}
						</nav>
					</div>
					<div className='dualbar-content'>
						
						<div className="company-logo"><img src={duallogouniverse} /></div>
						<nav>
							{isLoggedIn && (<Link to="/universe/nue/overview">Overview</Link>)}
							{isLoggedIn && (<Link to="/">Networks</Link>)}
							{isLoggedIn && (<Link to="/">Users</Link>)}
							{isLoggedIn && (<Link to="/">Topics</Link>)}
							{isLoggedIn && (<Link to="/">Owner</Link>)}
							{isLoggedIn && (<Link to="/">Details</Link>)}
							
						</nav>

					</div>



				</div>
		}
			</>
	);
}
export default Sidebar;