import React, { useContext} from 'react';
import { Button } from '@mui/material';

import AuthContext from '../../../context/AuthContext.js';
import ModalContext from '../../../context/ModalContext.js';

const DeleteNetworkModal = (props) => {

  const { details } = props;

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  
  const handleDeleteNetwork = () => {
    console.log("deleting");
    fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + details.id, {
      method: 'DELETE',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == "success") {
        modalCtx.closeModal();
      } else {
        console.error("Error while deleting network...");
      }
    }).catch((err) => {
      console.error("Error while deleting network...", err);
    });

  }

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }


  return (
    <div className="modal">
      <p>Are you sure you want to delete this Network?</p>

      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => { handleDeleteNetwork() }}>Yes</Button>
      </div>
    </div>
  )
}

export default DeleteNetworkModal;
