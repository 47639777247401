//react
import { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//Layout
import Sidebar from './Layout/Main/Sidebar';

//pages
import Login from "./pages/Login/Login";
import Overview from "./pages/Overview/Overview";
import Users from "./pages/Users/Users";
import Networks from "./pages/Networks/Networks";
import Administrators from "./pages/Administrators/Administrators";
import Tickets from "./pages/Tickets/Tickets";
import Topics from './pages/Topics/Topics';
import Universe from './pages/Universe/Universe';


//context
import AuthContext from './context/AuthContext';
import { DrawerContextProvider } from "./context/DrawerContext";
import { ModalContextProvider } from "./context/ModalContext";

//drawer & modal
import DetailsDrawer from "./common/DetailsDrawer";
import DetailsModal from "./common/DetailsModal";
import UniverseNue from './pages/Universe/UniverseNue';
import UniverseNueOverView from './pages/Universe/UniverseNueOverView';


function App() {

  const authCtx = useContext(AuthContext)


  return (
    <main className="app-container">
      <DrawerContextProvider>
        <ModalContextProvider>
          <BrowserRouter>
            {/* <Navbar /> */}
            <div className="main-container">
              <Sidebar />
              <div className='main'>
                <Routes>
                  <Route path="/" exact index element={!authCtx.isLoggedIn ? <Navigate to='/login' /> : <Navigate to='/overview' />} />
                  <Route path="/login" exact element={!authCtx.isLoggedIn ? <Login /> : <Navigate to='/overview' />} />
                  <Route path="/topics" exact element={authCtx.isLoggedIn ? <Topics /> : <Navigate to='/login' />} />
                  <Route path="/overview" exact element={authCtx.isLoggedIn ? <Overview /> : <Navigate to='/login' />} />
                  <Route path="/networks" exact element={authCtx.isLoggedIn ? <Networks /> : <Navigate to='/login' />} />
                  <Route path="/tickets" exact element={authCtx.isLoggedIn ? <Tickets /> : <Navigate to='/login' />} />
                  <Route path="/users" exact element={authCtx.isLoggedIn ? <Users /> : <Navigate to='/login' />} />
                  <Route path="/universe" exact element={authCtx.isLoggedIn ? <Universe /> : <Navigate to='/login' />} />
                  <Route path="/universe/nue/" exact element={authCtx.isLoggedIn ? <UniverseNue/> : <Navigate to='/login' />} />
                  <Route path="/universe/nue/overview" exact element={authCtx.isLoggedIn ? <UniverseNueOverView/> : <Navigate to='/login' />} />
                  <Route path="/administrators" exact element={authCtx.isLoggedIn ? <Administrators /> : <Navigate to='/login' />} />
                </Routes>
              </div>
            </div>
            {/* <Footer /> */}
            <DetailsDrawer />
            <DetailsModal />
          </BrowserRouter>
        </ModalContextProvider>
      </DrawerContextProvider>
    </main>
  );
}

export default App;
