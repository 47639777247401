import React, { useState, useEffect, useContext } from 'react';

import { Chip, Menu, MenuItem, Switch, TablePagination } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';


import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import DrawerContext from '../../../context/DrawerContext.js';

import { ref, getDownloadURL } from "firebase/storage";
import storage from "../../../common/FirebaseConfig";
import { useLocation } from 'react-router-dom';
import ModalContext from '../../../context/ModalContext';

const noMenuRoutes = ["/login"];

function NetworkImage({ coverRef, title }) {

  const { pathname } = useLocation();

  const [networkImage, setNetworkImage] = useState("");

  let bgColorForSpan = ''

  useEffect(() => {
    if (coverRef != "") {
      getImg(coverRef);
    }
  }, [coverRef]);

  const getRandomColor = (name) => {
    let first = name?.split(' ')[0];
    let firstAlphabet = first.charAt(0).toLowerCase();
    let asciiCode = firstAlphabet.charCodeAt(0);

    let colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

    let num = Math.round(0xffffff * parseInt(colorNum));
    let r = num >> 16 & 255;
    let g = num >> 8 & 255;
    let b = num & 255;

    bgColorForSpan = 'rgb(' + r + ', ' + g + ', ' + b + ', 0.3)'
    return <div style={{ background: bgColorForSpan }} className="network-initial">{firstAlphabet.toUpperCase()}</div>
  }


  const getImg = (coverRef) => {
    getDownloadURL(ref(storage, coverRef))
      .then((url) => {
        setNetworkImage(url);
      })
      .catch((error) => {
        console.log(error)
      });
  }


  if (noMenuRoutes.some((item) => pathname.includes(item))) return null;

  return (
    <>
      {networkImage == "" ? getRandomColor(title) : <img className='network-image' src={networkImage} alt="Network image" />}
    </>
  );
}


const rows = [];

function NetworksTable(props) {

  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);
  const { searchTerm } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [networks, setNetworks] = useState([]);
  const [topics, setTopics] = useState([])
  const [filteredNetworks, setFilteredNetworks] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [10, 20, 30];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [startNo, setStartNo] = useState(0);
  const [endNo, setEndNo] = useState(rowsPerPageOptions[0]);
  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor element

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };
  function toggleModalHandler(modal, data) {
    setAnchorEl(null);
    modalCtx.setDetails(modal, data);
    modalCtx.openModal();

    // console.log(modal, data);
  };

  useEffect(() => {

    fetch(process.env.REACT_APP_API_URI + '/v2/hubs', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setIsLoading(false);
        setNetworks(data.data);
      });

    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v2/topics', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setIsLoading(false);
        setTopics(data.data)
      });

  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filterNetworks = (term) => {
        return networks?.filter((data) => {
          return Object.values(data)?.join('')?.toLowerCase()?.includes(term?.toLowerCase());
        })
      }
      setFilteredNetworks(filterNetworks(searchTerm));
      setPage(0);
      setStartNo(0);
      setEndNo(rowsPerPage);
    } else {
      setFilteredNetworks(networks);
    }
  }, [networks, networks?.length, searchTerm]);



  const addFeatured = (event, id) => {
    let newNetworks = [];
    networks.forEach((net) => {
      if (net.id == id) {
        if (event == "checked") {
          net.featured = "on";
          updateFeatured(net.id, "on");
        } else {
          net.featured = "off";
          updateFeatured(net.id, "off");
        }
      }
      newNetworks.push(net);
    });
    setNetworks(newNetworks);
  };

  const updateFeatured = (id, featured) => {

    let payload = {
      id: id,
      featured: featured
    }

    fetch(process.env.REACT_APP_API_URI + '/v2/hubs/' + id + '/setFeatured', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + localStorage.token },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
    });

  }

  const isChecked = (id) => {
    let currentNetwork = networks.find(function (obj) { return obj.id == id });
    if (currentNetwork && currentNetwork.featured == "on") {
      return true;
    }
    return false;
  }

  console.log("networks", networks);
  console.log("topics", topics);

  const handleSelectAllRows = (event) => {
    if (event.target.checked) {
      const newSelectedRows = filteredNetworks.map((row) => row.id);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelectedRows);
  };

  const handleChangePage = (event, newPage) => {
    setStartNo(newPage * rowsPerPage);
    if (newPage * rowsPerPage + rowsPerPage < filteredNetworks.length || newPage * rowsPerPage + rowsPerPage == filteredNetworks.length) {
      setEndNo(newPage * rowsPerPage + rowsPerPage);
    } else {
      setEndNo(filteredNetworks.length);
    }

    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element to the clicked element
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const showBgColor = (status) => {
    // console.log(status);
    if (status == 'deleted') {
      return { backgroundColor: '#c0392b', color: '#fff' };
    } else {
      return { backgroundColor: '#27ae60', color: '#fff' };
    }
  }

  if (isLoading) {
    return <section><CircularProgress /></section>
  }

  return (
    <>
      <TableContainer className='network-table'>
        <Table className='table' aria-label="simple table" data-aos="fade-up">
          <TableHead style={{ width: '100%', position: "sticky", top: "0", backgroundColor: '#F0F1F3', color: '#9B9B9B', zIndex: "100" }} >
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox indeterminate={selectedRows.length > 0 && selectedRows.length < filteredNetworks.length} checked={selectedRows.length === filteredNetworks.length} onChange={handleSelectAllRows} />
              </TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {filteredNetworks?.slice(startNo, endNo).map((row) => (
              <TableRow key={row.id} hover style={{ backgroundColor: '#fff' }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={(event) => handleSelectRow(event, row.id)}
                  />
                </TableCell>
                <TableCell><div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}><NetworkImage coverRef={row.cover || ""} title={row.title} /><span>{row.title}</span></div></TableCell>
                <TableCell>{topics?.map(topic => topic.id === row.topic ? topic.topic : null)}</TableCell>
                <TableCell>{row.hubType}</TableCell>
                <TableCell>{row.points}</TableCell>
                <TableCell><Chip label={row.role ? row.role : "Not Available"} style={showBgColor(row.role ? row.role : "Not Found")} onClick={() => {
                  // setFilterStatus(row.role);
                  // onRoleChange(row.role);
                  // console.log("clicking", row.role);
                }} /></TableCell>
                <TableCell>
                  {isChecked(row.id) ? <BookmarkIcon onClick={(e) => addFeatured("unchecked", row.id)} /> : <BookmarkBorderIcon onClick={(e) => addFeatured("checked", row.id)} />}
                  <MoreVertIcon className="table-tool" onClick={handleClick}
                  //  onClick={() => {toggleDrawerHandler('user-details', row)}}
                  />

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ marginTop: '48px', boxShadow: 'none' }}
                  >
                    <MenuItem onClick={() => { toggleModalHandler('delete-network', row) }}  >Delete</MenuItem>
                    <MenuItem onClick={() => { toggleDrawerHandler('user-details', row) }} >Details</MenuItem>
                  </Menu></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="table-pagination" style={{ width: '100%', position: "sticky", bottom: "0", right: "0", left: "0", backgroundColor: '#F0F1F3', zIndex: "100" }}>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredNetworks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </TableContainer>

    </>
  );
}

export default NetworksTable;