import React from 'react';
import { useNavigate } from "react-router-dom";



import universe4 from '../../assets/img/universeimage1.png';
import universe5 from '../../assets/img/universeimage2.png';
import universe1 from '../../assets/img/universeimage3.png';
import universe6 from '../../assets/img/universeimage4.png';
import universe2 from '../../assets/img/universeimage5.png';
import universe3 from '../../assets/img/universeimage6.png';
import deleteimg from '../../assets/img/delete.svg';
import eye from '../../assets/img/eye.svg';



const Universe = () => {

// hooks
const navigate = useNavigate()

//functions
const handleClickUniverse = () => {
    
navigate("/universe/nue")

}
    // test data
    const TestData = [
        { name: 'Movie Buff', createdBy: 'Mark Louis' , image :universe1},
        { name: 'Movie Buff', createdBy: 'Mark Louis', image: universe2 },
        { name: 'Saurav', createdBy: 'Mark Louis', image: universe3 },
        { name: 'Nue Capital', createdBy: 'Annalisa Lonán', image: universe4 },
        { name: 'United Way', createdBy: 'Tikva Quanah', image: universe5 },
        { name: 'Music Studio', createdBy: 'Tikva Quanah', image: universe6 },
    ];

    return (
        <div className="universe-page">
            <header className='universe-header'>
                <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Universe</h1>
                <div className='action-bar' >
                    <button className='btn-primary btn-universe'>Create Universe</button>
                </div>
            </header>

            <section className='universe-section' >
                <div className='universe-container'>
                    {
                        TestData.map((item, index) => (
                            <div key={index} className='universe-content' onClick={handleClickUniverse} >
                        
                                <div className='universe-main'>
                                    <img src={item?.image} alt="img" />
                            
                                    <div>

                                        <h4>{item?.name}</h4>
                                        <div className='middle-content'>
                                            <div className='content-item'><p>Created by</p>-<h1>{item?.createdBy}</h1></div>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div className='bottom-content'>
                                                    <img src={eye} alt='vieweye' />
                                                    <p>Views</p>-<span>50</span>
                                                </div>
                                            </div>
                                            <img src={deleteimg} alt='delete' />
                                        </div>

                                    </div>
                            
                                </div>
                        
                            </div>
                        ))
                    }

                </div>
            </section>
        </div>
    );
}
export default Universe;