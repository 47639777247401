import React, { useContext, useState, useEffect } from 'react';

import NetworksTable from "./components/NetworksTable";

import DrawerContext from '../../context/DrawerContext.js';

//components
import TextInput from '../../common/TextInput'

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Clear } from '@mui/icons-material';
AOS.init();

const Networks = () => {

  const drawerCtx = useContext(DrawerContext);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [searchTerm, setSearchTerm] = useState("")

  const showSearchBar = () => (
    <div className="search-bar">
      {/* <input type="search" name="" id="search-field" className="search-field" placeholder="Type to search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
      <TextInput placeholder="Type to search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      {searchTerm && <Clear className="clear-search-icon" onClick={() => setSearchTerm("")} />}
    </div>
  );

  return (
    <div className="networks-page">

      <header className="template-header" >
        {/* <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Networks &nbsp; {loadingBlogs ? <CircularProgress size={22} /> : null}</h1> */}
        <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Networks</h1>
        <div className='action-bar' >
          {showSearchBar()}
          <div data-aos="fade-up" data-aos-delay="400">
            <button className="btn-primary" onClick={() => { toggleDrawerHandler('create-network', null) }}	>Create</button>
          </div>
        </div>
      </header>

      <section className='template-section' >
        <div className='networks'>
          <NetworksTable searchTerm={searchTerm} />
        </div>
      </section>
    </div>
  );

}
export default Networks;